.ContentLoadingScreen {
	border-radius: 10px;
	padding: 2.5vh;
	min-width: 40vw;
    background-color: white;
	display: flex;
	justify-content: center;
	overflow: hidden;
	align-items: stretch;
	max-width: 55vw;
	// min-height: 55vh;
	margin: auto;
}

.mac {
	margin: auto;
	margin-top: -5%;
	width: 95%;
}

.loading-logo {
	position: fixed;
	z-index: 1;
	height: 30%;
	width: 20%;
	margin-top: 5%;
}

.loader {
	height: 5px;
	width: 20%;
	border-radius: 50px;
	margin-top: 20%;
	position: fixed;
	z-index: 2;
}

.progress-bar {
	height: 5px;
	border-radius: 50px;
	margin-top: 2%;
	background-color: white !important;
	position: relative;
	z-index: 2;
	max-width: 100%;
}

.loading-bar {
	height: 5px;
	width: 100%;
	border-radius: 50px;
	margin-top: -2%;
	background-color: gray!important;
	// position: relative;
	z-index: 1;
}

@media (max-width: 800px) {
	.ContentLoadingScreen {
		// height: 25vh;
		max-width: 100%;
	}
	// .mac {
	// 	max-width: 50%;
	// 	// width: 95%;
	// }
	.loading-logo {
		height: auto;
		width: 32vw;
		margin-top: 9%;
	}
	.loader {
		margin-top: 41%;
		width: 32%;
	}
	.loading-bar {
		margin-top: -4%;
	}
}