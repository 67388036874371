.SideNavBar-Close {
	width: 0%;
	height: 100%;
	position: fixed;
	z-index: 1;
	top: 0;
	left: 0;
	overflow: hidden;
	transition: 0.5s;
	padding-top: 60px;
	background-color: white;
}

.SideNavBar-Open {
	width: 8%;
	height: 100%;
	position: fixed;
	z-index: 1;
	top: 0;
	left: 0;
	overflow-x: hidden;
	transition: 0.5s;
	background-color: white;
}

@media screen and (max-width: 800px) {
	.SideNavBar-Open {
		width: 20%;
	}
}

@media screen and (max-height: 800px) {
	.SideNavBar-Open {
		overflow-y: auto;
	}
}

.SideNavBarIcons {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 90vh;
	margin-top: 7vh;
	overflow-y: hidden;
}