.SideNavBar-Intro {
    min-width: 8.5%;
	height: 100%;
	position: fixed;
	z-index: 1;
	top: 0;
	left: 0;
}

@media screen and (max-width: 800px) {
	.SideNavBar-Intro {
		min-width: 21%;
	}
}

.SideNavBar-Intro-Tooltip {
    font-weight: bold;
}