.App {
	text-align: center;
	place-items: center;
	display: flex;
	height: 100vh;
	width: 100vw;
	overflow: hidden;
}

* {
	-ms-overflow-style: none;
}

::-webkit-scrollbar {
	display: none;
}